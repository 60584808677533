<template>
  <section class="assessment">
    <v-sheet class="quiz">
      <v-img :src="require('../../../assets/images/top-banner.png')">
        <div class="quiz__title black--text">
          {{ assessment.title }}
        </div>
      </v-img>
      <v-row align="center" justify="center" class="my-5">
        <v-col cols="12" lg="10">
          <div class="d-flex justify-right" v-if="this.assessment.time > 0">
            <v-spacer></v-spacer>
            <div class="">Timer : <span id="timer" /></div>
          </div>
          <div class="d-flex justify-center align-center">
            <span
              color="success"
              class="dots quiz__dots mx-1"
              v-for="(item, i) in assessment.questions"
              :key="i"
            ></span>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mb-2">
        <v-col cols="11" lg="8">
          <div v-for="(item, i) in questions" :key="i">
            <div v-if="qnum === i && !setting">
              <!-- question wrapper text -->
              <v-sheet class="quiz__wrapper">
                <div class="quiz__question pr-5" 
                :class="dark?'white--text': 'black--text'" 
                v-html="item.question"/>
              </v-sheet>
              <!-- choices -->
              <v-item-group
                mandatory
                class="mt-10 mb-5"
                :multiple="
                  item.type == 'sur'
                    ? item.is_multiple === 1
                      ? true
                      : false
                    : false
                "
                v-if="
                  item.type == 'mc' || item.type == 'sur' || item.type == 'tof'
                "
                v-model="answers[i]"
              >
                <v-row align="center" justify="center">
                  <v-col cols="12" lg="10">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        v-for="(choice, j) in item.choices"
                        :key="j"
                      >
                        <v-item v-slot="{ active, toggle }" :value="choice">
                          <v-card
                            :color="active ? 'primary' : 'white'"
                            class="d-flex align-center pa-3"
                            dark
                            rounded="lg"
                            @click="toggle"
                          >
                            <v-scroll-y-transition>
                              <div v-if="active" class="font">
                                {{ choice }}
                              </div>
                              <div v-else class="black--text">
                                {{ choice }}
                              </div>
                            </v-scroll-y-transition>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                    <v-text-field
                      v-if="item.type == 'sur'"
                      class="mt-7 col-lg-9 mx-auto"
                      label="Other answer...."
                      v-model="support_answers[i]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-item-group>

              <div v-else class="d-flex justify-center align-center mt-10">
                <v-text-field
                  class="col-lg-10"
                  label="Type your answer here.."
                  v-model="answers[i]"
                ></v-text-field>
              </div>
            </div>
          </div>

          <!-- btn navigation -->
          <div class="d-flex justify-end">
            <v-btn
              @click="prev"
              v-if="qnum !== 0"
              class="quiz__btn"
              text
              color="danger"
              large
            >
              <v-icon left size="30" class="mr-4">mdi-chevron-left</v-icon>
              Prev
            </v-btn>
            <v-btn
              v-if="qnum === assessment.questions.length - 1"
              @click="submit"
              class="quiz__btn"
              color="success"
              large
            >
              Submit
              <v-icon right size="20">mdi-send</v-icon>
            </v-btn>
            <v-btn
              v-else
              @click="next"
              outlined
              class="quiz__btn"
              color="success"
              large
            >
              Next
              <v-icon right size="30">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-img :src="require('../../../assets/images/bottom-banner.png')" />
    </v-sheet>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["assessment", "dark"],
  data: () => ({
    qnum: 0,
    answers: [],
    support_answers: [],
    setting: true,
    questions: [],
    qid: [],
    form: [],
    countdown: null,
  }),
  mounted() {
    let dots = document.querySelectorAll(".dots");
    dots[0].classList.add("quiz__dots-active");

    if (this.assessment.time > 0) {
      this.startTimer(
        this.assessment.time * 60,
        document.querySelector("#timer")
      );
    }

    this.setanswer();
    console.log(this.questions);
  },
  methods: {
    ...mapActions("student", ["assessmentStoreAction"]),

    async setanswer() {
      let vm = this;
      await vm.shuffle();
      await vm.questions.forEach((el) => {
        vm.qid.push({ id: el.id, type: el.type });
        vm.answers.push("");
        vm.support_answers.push("");
      });
      vm.setting = false;
    },
    next() {
      let vm = this;
      let dots = document.querySelectorAll(".dots");
      console.log(vm.answers);
      if (vm.qnum < vm.questions.length - 1) {
        vm.qnum = ++vm.qnum;
        console.log(vm.qnum);
        dots[vm.qnum].classList.add("quiz__dots-active");
        return;
      }
    },
    prev() {
      let vm = this;
      let dots = document.querySelectorAll(".dots");

      if (vm.qnum !== 0 && vm.qnum <= vm.questions.length - 1) {
        dots[vm.qnum].classList.remove("quiz__dots-active");
        vm.qnum = --vm.qnum;
        console.log(vm.qnum);
        return;
      }
    },
    submit() {
      clearInterval(this.countdown);
      this.save();
      this.$emit("submitted");
    },
    save() {
      let vm = this;

      vm.qid.forEach((el, i) => {
        vm.form.push({
          id: el.id,
          type: el.type,
          answers: vm.answers[i],
          support_answer: vm.support_answers[i],
        });
      });
      vm.assessmentStoreAction({ answer: vm.form, id: vm.assessment.id });
      vm.form = [];
    },
    shuffle() {
      this.questions = this.assessment.questions.sort(
        () => Math.random() - 0.5
      );
    },
    startTimer(duration, display) {
      var timer = duration,
        minutes,
        seconds;
      var vm = this;
      vm.countdown = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
          clearInterval(vm.countdown);
          vm.save();
          vm.$emit("timeover");
        }
      }, 1000);
    },
  },
};
</script>