<template>
  <section class="mt-2">
    <v-sheet>
      <v-img :src="require('../../../assets/images/top-banner.png')"></v-img>
      <v-row class="my-16" justify="center" align="center">
        <v-col cols="12" xl="6">
          <v-sheet class="assessment px-10">
            <div class="text-center display-1">
              {{ status === "Time over" ? "TIME OVER" : "SUCCESS" }}
            </div>
            <div class="text-center caption">
              {{
                status === "Time over"
                  ? "You reach the assessment limit"
                  : "You successfully submitted your assessment"
              }}
            </div>
            <div class="text-center my-5">
              <div class="display-4">
                {{score.score}}
              </div>
              <div class="overline">YOUR SCORE</div>
            </div>
            <div class="d-flex justify-center">
              <v-btn outlined text color="primary" @click="$router.back()">
                <v-icon left> mdi-arrow-left </v-icon>
                Back to topic
              </v-btn>
              <v-btn disabled text color="red" @click="$emit('retake')">
                <v-icon left> mdi-reload </v-icon>
                Retake Assessment
              </v-btn>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-img :src="require('../../../assets/images/bottom-banner.png')"></v-img>
    </v-sheet>
  </section>
</template>

<script>
export default {
  props: ["status", "score"],
};
</script>