var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"assessment"},[_c('v-sheet',{staticClass:"quiz"},[_c('v-img',{attrs:{"src":require('../../../assets/images/top-banner.png')}},[_c('div',{staticClass:"quiz__title black--text"},[_vm._v(" "+_vm._s(_vm.assessment.title)+" ")])]),_c('v-row',{staticClass:"my-5",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"10"}},[(this.assessment.time > 0)?_c('div',{staticClass:"d-flex justify-right"},[_c('v-spacer'),_c('div',{},[_vm._v("Timer : "),_c('span',{attrs:{"id":"timer"}})])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center align-center"},_vm._l((_vm.assessment.questions),function(item,i){return _c('span',{key:i,staticClass:"dots quiz__dots mx-1",attrs:{"color":"success"}})}),0)])],1),_c('v-row',{staticClass:"mb-2",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"11","lg":"8"}},[_vm._l((_vm.questions),function(item,i){return _c('div',{key:i},[(_vm.qnum === i && !_vm.setting)?_c('div',[_c('v-sheet',{staticClass:"quiz__wrapper"},[_c('div',{staticClass:"quiz__question pr-5",class:_vm.dark?'white--text': 'black--text',domProps:{"innerHTML":_vm._s(item.question)}})]),(
                item.type == 'mc' || item.type == 'sur' || item.type == 'tof'
              )?_c('v-item-group',{staticClass:"mt-10 mb-5",attrs:{"mandatory":"","multiple":item.type == 'sur'
                  ? item.is_multiple === 1
                    ? true
                    : false
                  : false},model:{value:(_vm.answers[i]),callback:function ($$v) {_vm.$set(_vm.answers, i, $$v)},expression:"answers[i]"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('v-row',_vm._l((item.choices),function(choice,j){return _c('v-col',{key:j,attrs:{"cols":"12","sm":"6"}},[_c('v-item',{attrs:{"value":choice},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var active = ref.active;
                  var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center pa-3",attrs:{"color":active ? 'primary' : 'white',"dark":"","rounded":"lg"},on:{"click":toggle}},[_c('v-scroll-y-transition',[(active)?_c('div',{staticClass:"font"},[_vm._v(" "+_vm._s(choice)+" ")]):_c('div',{staticClass:"black--text"},[_vm._v(" "+_vm._s(choice)+" ")])])],1)]}}],null,true)})],1)}),1),(item.type == 'sur')?_c('v-text-field',{staticClass:"mt-7 col-lg-9 mx-auto",attrs:{"label":"Other answer...."},model:{value:(_vm.support_answers[i]),callback:function ($$v) {_vm.$set(_vm.support_answers, i, $$v)},expression:"support_answers[i]"}}):_vm._e()],1)],1)],1):_c('div',{staticClass:"d-flex justify-center align-center mt-10"},[_c('v-text-field',{staticClass:"col-lg-10",attrs:{"label":"Type your answer here.."},model:{value:(_vm.answers[i]),callback:function ($$v) {_vm.$set(_vm.answers, i, $$v)},expression:"answers[i]"}})],1)],1):_vm._e()])}),_c('div',{staticClass:"d-flex justify-end"},[(_vm.qnum !== 0)?_c('v-btn',{staticClass:"quiz__btn",attrs:{"text":"","color":"danger","large":""},on:{"click":_vm.prev}},[_c('v-icon',{staticClass:"mr-4",attrs:{"left":"","size":"30"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Prev ")],1):_vm._e(),(_vm.qnum === _vm.assessment.questions.length - 1)?_c('v-btn',{staticClass:"quiz__btn",attrs:{"color":"success","large":""},on:{"click":_vm.submit}},[_vm._v(" Submit "),_c('v-icon',{attrs:{"right":"","size":"20"}},[_vm._v("mdi-send")])],1):_c('v-btn',{staticClass:"quiz__btn",attrs:{"outlined":"","color":"success","large":""},on:{"click":_vm.next}},[_vm._v(" Next "),_c('v-icon',{attrs:{"right":"","size":"30"}},[_vm._v("mdi-chevron-right")])],1)],1)],2)],1),_c('v-img',{attrs:{"src":require('../../../assets/images/bottom-banner.png')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }