<template>
  <section id="assessment">
    <v-row justify="center" align="center">
      <v-col cols="12" xl="8" lg="10">
        <breadcrumbs :links="links" :dark="user.dark" />
        <div v-if="!end.value">
          <Preview
            v-if="!start"
            :assessment="assessment"
            @take="start = true"
          />
          <Assessment
            v-else
            :assessment="assessment"
            :dark="user.dark"
            @submitted="submitted"
            @timeover="timeover"
          />
        </div>

        <Ended v-else :status="end.status" @retake="retake" :score="score"/>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Preview from "../components/assessment/Preview.vue";
import Assessment from "../components/assessment/Assessment.vue";
import Ended from "../components/assessment/Ended.vue";

export default {
  components: {
    Preview,
    Assessment,
    Ended,
  },
  data: () => ({
    links: [],
    start: false,
    end: {
      value: false,
      // submitted || time-over
      status: "",
    },
  }),
  computed: {
    ...mapState("student", {
      assessment: (state) => state.assessment,
      user: (state) => state.user,
      score: (state) => state.score,
    }),
  },
  mounted() {
    this.scoreMutation([])
    this.fetch()
  },
  methods: {
    ...mapActions("student", [
      "assessmentGetAction",
      "lessonAssessmentGetAction",
      'getScoreAction'
    ]),

    ...mapMutations('student', [
      'scoreMutation'
    ]),

    fetch() {
      if (this.$route.params.type == "sub-lesson") {
      this.assessmentGetAction(this.$route.params.uuid).then(() => {
        this.setLinks1();
      });
      return;
      }
      this.lessonAssessmentGetAction(this.$route.params.uuid).then(() => {
        this.setLinks2();
      });
    },

     submitted() {
      this.end.value = true;
      this.end.status = "Submitted";
      this.start = false;
    },

    setLinks1() {
      this.links.push(
        {
          name: this.assessment.quizable.lesson.course.title,
          link: true,
          to: {
            name: "Student Course Details",
            params: this.assessment.quizable.lesson.course.uuid,
          },
        },
        {
          name: this.assessment.quizable.title,
          link: true,
          to: {
            name: "Student Material",
            params: this.assessment.quizable.uuid,
          },
        },
        {
          name: this.assessment.title,
          link: false,
          to: {
            name: "",
            params: "",
          },
        }
      );
    },

    setLinks2() {
      this.links.push(
        {
          name: this.assessment.quizable.course.title,
          link: true,
          to: {
            name: "Student Course Details",
            params: this.assessment.quizable.course.uuid,
          },
        },
        {
          name: this.assessment.title,
          link: false,
          to: {
            name: "",
            params: "",
          },
        }
      );
    },

    timeover() {
      this.end.value = true;
      this.end.status = "Time over";
      this.start = false;
    },

    retake() {
      this.end.value = false;
      this.end.status = "";
      this.start = true;
    },
  },
};
</script>