<template>
  <section class="mt-5">
    <v-sheet>
      <v-img :src="require('../../../assets/images/top-banner.png')"></v-img>
      <v-row justify="center" align="center">
        <v-col cols="12" xl="6" lg="8">
          <v-sheet class="assessment px-10 py-5">
            <div class="assessment__title">
              {{ assessment.title }}
            </div>
            <div class="d-flex">
              <div class="assessment__subtitle">
                <v-icon size="14">mdi-file-outline</v-icon>
                Items: {{ assessment.item }}
              </div>
              <div v-if="assessment.time" class="ml-5 assessment__subtitle">
                <v-icon size="14">mdi-timer-outline</v-icon>
                Time: {{ assessment.time }} min
              </div>
            </div>

            <v-btn @click="$emit('take')" color="red" dark class="mt-5"
              >Take Assessment</v-btn
            >
          </v-sheet>
        </v-col>
      </v-row>
      <v-img :src="require('../../../assets/images/bottom-banner.png')"></v-img>
    </v-sheet>
  </section>
</template>

<script>
export default {
  props: ["assessment"],
};
</script>